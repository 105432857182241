<template>
  <v-card
    class="pa-2 dialog-content"
    :style="{
      borderRadius: $vuetify.breakpoint.smAndDown ? '0px' : '',
      height: '100%',
    }"
  >
    <v-card-title class="modal-title">
      <div class="d-flex justify-space-between w-100 align-center">
        <ph-qr-code :size="30" color="#808081" />
        <block-text
          color="trolleyGrey"
          class="pl-2 text-red"
          :text="$t('dialog.recognizedUser')"
          type="body-1"
        />
        <ph-x
          :size="24"
          class="pointer"
          :color="baseFontColor"
          @click="close"
        />
      </div>
    </v-card-title>
    <v-card-text class="justify-center align-center">
      <div class="text-center">
        <div class="mt-3">
          <v-avatar color="rgba(190, 191, 192, 0.25)" size="64">
            <v-img
              v-if="user.user.profileImageUrl"
              max-width="80"
              :src="user.user.profileImageUrl"
            />
            <ph-user
              v-else
              :size="40"
              :style="{ maxWidth: '50px' }"
              :color="'rgba(128, 128, 129, 1)'"
            />
          </v-avatar>
        </div>
        <div class="mt-3">
          <block-text
            :id="'userName'"
            color="primary"
            :text="username"
            weight="bold"
            type="body-2"
          />
        </div>
        <div>
          <block-text :id="'userName'" :text="fullName" :type="'subtitle'" />
        </div>

        <div class="d-flex align-center justify-center mt-4">
          <block-text
            :id="'userName'"
            :text="$t('dialog.accountNumber') + ':'"
            :type="'caption'"
            class="mr-2"
          />
          <block-text
            :id="'userName'"
            :text="shortAccountNumber"
            :type="'body-2'"
            color="primary"
          />
        </div>

        <div class="d-flex justify-center align-center mt-2">
          <block-text
            :text="$t('header.walletAmount') + ':'"
            :type="'caption'"
            class="mr-2"
          />

          <div class="d-flex text-center justify-center align-center">
            <div class="d-flex align-center">
              <div class="wallet-icon-container">
                <ph-wallet :size="20" class="mr-2" color="#000" />
                <v-img
                  class="wallet-ttu-coin"
                  width="14"
                  height="14"
                  :src="require('@/assets/ttuToken.svg')"
                />
              </div>
              <block-text
                :id="'userName'"
                :text="balance"
                :type="'body-2'"
                color="primary"
                class="pb-1"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex my-5 w-100">
        <v-divider></v-divider>
      </div>
      <div class="justify-center d-flex">
        <block-text
          :text="$t('dialog.enterAmountToDebit')"
          :type="'title'"
          weight="bold"
        />
      </div>

      <div class="d-flex justify-center align-center mt-5">
        <v-text-field
          ref="amountField"
          height="40"
          class="mx-4 text-center amount-field pt-0"
          :value="localValue"
          :prefix="selectedCurrency"
          :rules="[
            (v) =>
              debitAmount < balance ||
              debitAmount === 0 ||
              $t('dialog.notEnoughTTU'),
          ]"
          @input="change"
          type="number"
        ></v-text-field>
        <div>
          <currency-converter
            ref="currencyConverter"
            :amount="parseFloat(localValue)"
            @currencyChanged="onCurrencyChanged"
          />
        </div>
      </div>
    </v-card-text>

    <v-card-actions class="d-flex justify-space-between mt-6 pa-4">
      <v-btn
        :color="$vuetify.theme.dark ? '#fff' : '#222'"
        large
        plain
        block
        class="body-2 px-5 py-2 primary-btn"
        depressed
        outlined
        rounded
        @click="confirm"
        :disabled="
          parseFloat(debitAmount) === 0 ||
          parseFloat(debitAmount) > balance ||
          sendTTULoading
        "
      >
        <v-progress-circular
          v-if="sendTTULoading"
          indeterminate
          size="20"
        ></v-progress-circular>
        <div v-else class="d-flex align-center">
          <block-text
            :text="$t('dialog.confirmDebit')"
            weight="bold"
            type="body-2"
          />
          <div v-if="debitAmount > 0" class="d-flex align-center ml-2">
            <v-img
              class="mr-1"
              width="14"
              height="14"
              :src="require('@/assets/ttuToken.svg')"
            />
            <block-text
              :text="debitAmount + ' ' + $t('dialog.tokens')"
              type="body-1"
            />
          </div>
        </div>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { PhQrCode, PhUser, PhWallet, PhArrowLeft, PhX } from "phosphor-vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    PhQrCode,
    PhWallet,
    PhX,
    PhUser,
    PhArrowLeft,
    Avatar: () => import("@/components/Core/Avatar"),
    IncrementComponent: () => import("@/components/Core/IncrementComponent"),
    CurrencyConverter: () => import("@/components/Core/CurrencyConverter"),
  },
  data() {
    return {
      debitAmount: 0.0,
      localValue: 0,
      selectedCurrency: "€",
    };
  },
  props: {
    user: {
      type: [Object],
      required: true,
    },
  },
  watch: {
    localValue(val) {
      if (isNaN(val)) {
        this.localValue = 0;
      }
    },
  },
  computed: {
    ...mapGetters({
      sendTTULoading: "wallet/sendTTULoading",
    }),
    username() {
      return this.user.user.username ? this.user.user.username : "";
    },
    fullName() {
      return this.user.user.fullName;
    },
    accountNumber() {
      return this.user.walletId;
    },
    shortAccountNumber() {
      return this.user.walletId.split("-")[0];
    },
    balance() {
      return this.user.balance;
    },
    baseFontColor() {
      return this.$vuetify.theme.dark ? "#fff" : "#222";
    },
  },
  mounted() {
    this.$refs.amountField.focus();
  },
  methods: {
    ...mapActions({
      getUser: "wallet/getUser",
    }),
    close() {
      this.$emit("closeDialog");
    },
    async confirm() {
      const payload = {
        userProfileId: this.user.userProfileId,
        debitAmount: this.debitAmount,
      };
      const success = await this.$store.dispatch(
        "wallet/createTransaction",
        payload
      );
      if (!success) {
        this.$store.dispatch(
          "snackbar/show",
          {
            text: this.$t("dialog.createTransactionError"),
            color: "error",
          },
          { root: true }
        );
      } else {
        await this.$store.dispatch(
          "wallet/setTransferReceiverDebitAmount",
          this.debitAmount
        );
        this.$emit("setStep", 2);
        this.$store.dispatch(
          "snackbar/show",
          {
            text: this.$t("dialog.createTransactionSuccess"),
            color: "success",
          },
          { root: true }
        );
      }
      this.$emit("closeDialog");
      this.getUser();
    },
    back() {
      this.$emit("setStep", 0);
    },
    change(val) {
      if (parseFloat(this.localValue) < 0) {
        this.localValue = 0;
        return;
      }
      this.localValue = parseFloat(val);
    },
    onCurrencyChanged(payload) {
      this.selectedCurrency = payload.icon;
      this.debitAmount = payload.amount.toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn--outlined {
  border: 1px solid var(--v-silver-base) !important;
}
.modal-title {
  color: var(--v-trolleyGrey-base) !important;
}

.w-100 {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .dialog-content {
    position: fixed;
    width: 100%;
    overflow-y: auto;
  }
}
.wallet-ttu-coin {
  position: absolute;
  left: 12px;
  top: -3px;
  width: 14px;
  height: 14px;
}
.wallet-icon-container {
  position: relative;
}
.amount-field {
  font-size: 30px;
}
::v-deep
.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)
.v-btn__content {
  opacity: 1;
}
::v-deep .theme--light.v-input.error--text input {
  color: red;
}
::v-deep .v-text-field__slot {
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-text-field__prefix {
  line-height: 36px;
}
.v-text-field {
  &::v-deep input {
    padding: 0px;
    max-height: 37px;
  }
}
</style>
